/* eslint-disable  */
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import Pagination from '../components/Pagination';

import * as S from '../components/ListWrapper/styled';

const BlogList = (props) => {
  const PostList = props.data.allMarkdownRemark.edges;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : `/page/${currentPage - 1}`;
  const nextPage = `/page/${currentPage + 1}`;
  return (
    <Layout>
      <SEO title="Home" />
      <S.ListWrapper>
        {PostList.map(({
          node: {
            frontmatter: {
              background,
              category,
              date,
              description,
              title,
              color,
            },
            timeToRead,
            fields: {
              slug,
            },
          },
        }) => (
            <PostItem
              key={slug}
              slug={slug}
              background={background}
              category={category}
              date={date}
              timeToRead={timeToRead}
              title={title}
              description={description}
              color={color} 
            />
          ))}
      </S.ListWrapper>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  );
};

export const query = graphql`
  query PostList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          frontmatter {
            title
            description
            date(formatString: "MMMM DD[,] YYYY", locale: "en-us")
            category
            background
            color
          }
          timeToRead
          fields {
            slug
          }
        }
      }
    }
  }
`;
export default BlogList;
